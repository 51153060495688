import React, { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import AppNavigator from "../components/app-navigator/AppNavigator";

import HomePage from "./home-page/HomePage";

import SearchPage from "./search-page/SearchPage";
import SearchPageNotFound from "./search-page/SearchPageNotFound";
import FaqPage from "./faq-page/FaqPage";
import ContactUsPage from "./contact-us-page/ContactUsPage";
import AboutUsPage from "./about-us-page/AboutUsPage";
import PharmaciesPage from "./pharmacies-page/PharmaciesPage";
import GetCardPage from "./get-card-page/GetCardPage";
import GetCouponPage from "./get-coupon-page/GetCouponPage";
// import BlogWrapper from './blog-page/BlogWrapper';
import PressPage from "./press-page/PressPage";

import NotFoundPage from "./not-found-page/NotFoundPage";

// import WorkWithUsPage from "./work-with-us-page/WorkWithUsPage";

import ApplicationPage from "./application-page/ApplicationPage";
import ApplicationDeleteUserPage from "./application-delete-user-page/ApplicationDeleteUserPage";

import PrivacyPolicyPage from "./information-page/PrivacyPolicyPage";
import TermsConditionsPage from "./information-page/TermsConditionsPage";
import GetOrganizationCardPage from "./get-organization-card-page/GetOrganizationCardPage";
import PharmacyTargetPage from "./pharmacy-target-page/PharmacyTargetPage";
import WlPromoPage from "./white-label-promo/WlPromoPage";

export default function App() {
    const syncHeight = () => {
        document.documentElement.style.setProperty(
            "--window-inner-height",
            `${window.innerHeight}px`
        );
    };

    useEffect(() => {
        syncHeight();
        window.addEventListener("resize", syncHeight);
        return () => window.removeEventListener("resize", syncHeight);
    }, []);

    return (
        <BrowserRouter>
            <HelmetProvider>
                <AppNavigator />
                <Header />
                <Routes>
                    <Route path="/" element={<HomePage />} />
                    <Route path="/drug" element={<SearchPageNotFound />} />
                    <Route path="/drug/:drug" element={<SearchPage />} />

                    <Route path="/coupon" element={<GetCardPage />} />
                    <Route path="/coupon/:drug" element={<GetCouponPage />} />
                    <Route
                        path="/org/:couponCode"
                        element={<GetOrganizationCardPage />}
                    />

                    <Route path="/card" element={<GetCardPage />} />
                    <Route path="/card/:rxgrp" element={<GetCardPage />} />

                    <Route index path="/faq" element={<FaqPage />} />
                    <Route index path="/about-us" element={<AboutUsPage />} />
                    <Route index path="/press" element={<PressPage />} />

                    <Route
                        index
                        path="/contact-us"
                        element={<ContactUsPage />}
                    />
                    <Route
                        index
                        path="/pharmacies"
                        element={<PharmaciesPage />}
                    />

                    {/* Pharmacies Target Pages */}
                    <Route
                        index
                        path="/kroger"
                        element={<PharmacyTargetPage />}
                    />

                    <Route
                        index
                        path="/publix"
                        element={<PharmacyTargetPage />}
                    />

                    <Route
                        index
                        path="/walmart"
                        element={<PharmacyTargetPage />}
                    />

                    <Route index path="/cvs" element={<PharmacyTargetPage />} />

                    <Route
                        index
                        path="/rite-aid"
                        element={<PharmacyTargetPage />}
                    />

                    <Route
                        index
                        path="/walgreens"
                        element={<PharmacyTargetPage />}
                    />

                    <Route
                        index
                        path="/target"
                        element={<PharmacyTargetPage />}
                    />

                    {/* END Pharmacies Target Pages */}

                    <Route index path="/app" element={<ApplicationPage />} />
                    <Route
                        index
                        path="/app/delete-user"
                        element={<ApplicationDeleteUserPage />}
                    />

                    <Route index path="/wl-promo" element={<WlPromoPage />} />

                    <Route
                        index
                        path="/privacy-policy"
                        element={<PrivacyPolicyPage />}
                    />

                    <Route
                        index
                        path="/terms-conditions"
                        element={<TermsConditionsPage />}
                    />

                    <Route path="/not-found" element={<NotFoundPage />} />

                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
                <Footer />
            </HelmetProvider>
        </BrowserRouter>
    );
}
