import PropTypes from "prop-types";
import "../wl-promo-page.scss";

function BenefitItem({ icon, title, content }) {
    return (
        <div className="benefit-item">
            <div className="benefit-item_icon">
                <img
                    src={require(`../images/benefit-icons/${icon}_icon.png`)}
                />
            </div>
            <div className="benefit-item_title">
                <h3>{title}</h3>
            </div>
            <div className="benefit-item_content">
                <p>{content}</p>
            </div>
        </div>
    );
}

BenefitItem.propTypes = {
    icon: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
};

export default BenefitItem;
