import {
    DoCreateSubscriptionRequest,
    DoSendQuestionRequest,
    DoSendWlFormRequest,
} from "./api/api.info";

export const createSubscription = async (email) => {
    const result = await DoCreateSubscriptionRequest(email);
    return result;
};

export const sendQuestion = async (email, name, content) => {
    const result = await DoSendQuestionRequest({
        email,
        name,
        content,
    });
    return result;
};
export const sendWlForm = async (data) => {
    const result = await DoSendWlFormRequest(data);
    return result;
};
