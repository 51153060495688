import PropTypes from "prop-types";
import { useState } from "react";
import classnames from "classnames";
import LoadingBtn from "../loading-btn/LoadingBtn";

import { validateEmail } from "../../utilities/helpers";
import { sendWlForm } from "../../services/mail.service";

import "./contact-us-form.scss";

function WlContactForm({ className }) {
    const initedFormValues = {
        firstName: "",
        lastName: "",
        describesYou: "",
        jobTitle: "",
        companyName: "",
        email: "",
        phone: "",
        message: "",
    };
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const [formValues, setFormValues] = useState(initedFormValues);

    const errorClassNames = classnames({
        "contact__form--error": true,
        show: error !== null,
    });

    const componentClassNames = classnames({
        [className]: className,
        "component__contact-form": true,
        "success-send": success,
    });

    const validateForm = (values) => {
        let valid = true;
        if (!validateEmail(values.email)) {
            setError("Invalid email, please check and try again.");
            valid = false;
        }

        for (let key in values) {
            if (values[key].length === 0) {
                setError("Please fill all fields");
                valid = false;
            }
        }

        return valid;
    };

    const onSubmitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess(false);
        setError(null);

        if (validateForm(formValues)) {
            const result = await sendWlForm(formValues);
            if (result) {
                setSuccess(true);
                setTimeout(() => {
                    setFormValues(initedFormValues);
                    setSuccess(false);
                }, 2600);
            } else {
                setError("Something went wrong, please try again.");
            }
        }
        setLoading(false);
    };

    return (
        <div className={componentClassNames}>
            <form className="contact__form" onSubmit={onSubmitHandler}>
                <div className="contact__form--wrap">
                    <label className="contact__form--label">
                        First name
                        <input
                            value={formValues.firstName}
                            onChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    firstName: e.target.value,
                                })
                            }
                            type="text"
                            placeholder="First name"
                            className="input form-text contact__form--input"
                        />
                    </label>
                    <label className="contact__form--label">
                        Last name
                        <input
                            value={formValues.lastName}
                            onChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    lastName: e.target.value,
                                })
                            }
                            type="text"
                            placeholder="Last name"
                            className="input form-text contact__form--input"
                        />
                    </label>
                    <label className="contact__form--label">
                        Which best describes you?
                        <select
                            value={formValues.describesYou}
                            onChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    describesYou: e.target.value,
                                })
                            }
                            type="text"
                            className="input form-text contact__form--input"
                        >
                            <option disabled value="">
                                Please Select
                            </option>
                            <option value="Broker/Consultant">
                                Broker/Consultant
                            </option>
                            <option value="Company exploring discount card options">
                                Company exploring discount card options
                            </option>
                            <option value="Health Plan">Health Plan</option>
                            <option value="PBM">PBM</option>
                            <option value="Self-funded employer with 50,000 members or more">
                                Self-funded employer with 50,000 members or more
                            </option>
                            <option value="Self-funded employer with less than 50,000 members">
                                Self-funded employer with less than 50,000
                                members
                            </option>
                            <option value="Other">Other</option>
                        </select>
                    </label>
                </div>
                <div className="contact__form--wrap">
                    <label className="contact__form--label">
                        Job title
                        <input
                            value={formValues.jobTitle}
                            onChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    jobTitle: e.target.value,
                                })
                            }
                            type="text"
                            placeholder="Job title"
                            className="input form-text contact__form--input"
                        />
                    </label>
                    <label className="contact__form--label">
                        Company name
                        <input
                            value={formValues.companyName}
                            onChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    companyName: e.target.value,
                                })
                            }
                            type="text"
                            placeholder="Company name"
                            className="input form-text contact__form--input"
                        />
                    </label>
                    <label className="contact__form--label">
                        Your email address
                        <input
                            value={formValues.email}
                            onChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    email: e.target.value,
                                })
                            }
                            type="email"
                            placeholder="Enter your email"
                            className="input form-text contact__form--input"
                        />
                    </label>
                    <label className="contact__form--label">
                        Your phone number
                        <input
                            value={formValues.phone}
                            onChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    phone: e.target.value,
                                })
                            }
                            type="phone"
                            placeholder="Enter your email"
                            className="input form-text contact__form--input"
                        />
                    </label>
                </div>
                <div className="contact__form--wrap">
                    <label className="contact__form--label">
                        Message
                        <textarea
                            value={formValues.message}
                            onChange={(e) =>
                                setFormValues({
                                    ...formValues,
                                    message: e.target.value,
                                })
                            }
                            placeholder="Enter your message"
                            className="contact__form--textarea"
                        />
                    </label>
                </div>
                <div className="contact__form--wrap">
                    <LoadingBtn
                        classNames="contact__form--submit"
                        btnText="Submit"
                        isLoading={loading}
                    />
                </div>
                <div className={errorClassNames}>
                    <p>{error}</p>
                </div>
            </form>
            <div className="component__contact-form--success">
                <p className="success">
                    Thanks for your message! <br /> We&apos;ll get back to you
                    as soon as we can.
                </p>
            </div>
        </div>
    );
}

WlContactForm.propTypes = {
    className: PropTypes.string,
};

export default WlContactForm;
