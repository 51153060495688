import RxCard from "../../components/rx-card/RxCard";
import BenefitItem from "./benefit-item/BenefitItem";

import WlCardImage from "./images/wl-card/wl-charityrx-card.png";
import WlCardImage2x from "./images/wl-card/wl-charityrx-card@2x.png";
import WlCardImage3x from "./images/wl-card/wl-charityrx-card@3x.png";

import WlInfoAnimation from "./images/info-animation.png";

import BenefitItems from "./benefits.json";
import FaqItems from "./questions.json";
import "./wl-promo-page.scss";
import FaqQuestionItem from "../../components/faq-question-item/FaqQuestionItem";
import WlContactForm from "../../components/forms/WlContactForm";
import InfoVideoAnimation from "./images/info-animation.mp4";
import InfoVideoAnimationWebM from "./images/info-animation.webm";
import { useEffect, useRef } from "react";

function WlPromoPage() {
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    }, []);

    return (
        <div className="page-wl-promo">
            <div className="container-fluid">
                <div className="row">
                    <div className="wl_hero-section">
                        <div className="wl_hero-section__content">
                            <h1>
                                Rolling out your own discount card solution is
                                easy.
                            </h1>
                            <p className="subtitle">
                                Leverage the Advantage program as is or white
                                label your own solution to bring consistent, low
                                pricing to your underinsured populations.
                            </p>
                        </div>
                        <div className="wl_hero-section__cards">
                            <div className="section_cards-item">
                                <RxCard />
                            </div>
                            <div className="section_cards-item">
                                <div style={{ display: "flex" }}>
                                    <img
                                        className="component__rxcard--card"
                                        src={WlCardImage}
                                        srcSet={`${WlCardImage2x} 2x, ${WlCardImage3x} 3x`}
                                        alt="White Label CharityRx Card"
                                        draggable={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="wl_info-section">
                        <div className="wl_info-animation">
                            <video
                                ref={videoRef}
                                poster={WlInfoAnimation}
                                autoPlay={true}
                                loop={true}
                                muted
                                draggable={false}
                                className="wl_info-video"
                            >
                                <source
                                    src={InfoVideoAnimation}
                                    type="video/mp4"
                                />
                                <source
                                    src={InfoVideoAnimationWebM}
                                    type="video/webm"
                                />
                            </video>
                        </div>
                        <div className="wl_info-content">
                            <h2>
                                Traditional discount cards are often just
                                another form of spread pricing, ultimately,
                                passing on additional costs to consumers.
                            </h2>
                            <p>
                                Capital Rx Advantage is eliminating hidden fees
                                through a flat fee structure and ensuring
                                consumer data is never sold to third parties.
                                Have peace of mind with stability and security
                                in your discount card program.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="wl_benefits-section">
                        <div className="wl_benefits-title">
                            <h2>
                                Why Choose CharityRx for Your White-Label
                                Solution?
                            </h2>
                            <p>
                                At CharityRx, we understand that providing an
                                accessible, seamless experience for your users
                                is key to your success. That’s why we’ve
                                developed a white-label solution that is not
                                only easy to implement but also delivers real
                                value to your customers. Here’s why we stand out
                                from the competition:
                            </p>
                        </div>
                        <div className="wl_benefits-items">
                            {BenefitItems.map((item, key) => (
                                <BenefitItem
                                    key={key}
                                    title={item.title}
                                    content={item.content}
                                    icon={item.icon}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="wl_faq-section">
                        <div className="wl_faq-title">
                            <h2>Frequently Asked Questions</h2>
                        </div>
                        <div className="wl_faq-content">
                            {FaqItems.map((item, key) => (
                                <FaqQuestionItem key={key} item={item} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="btm-section">
                <div className="container">
                    <div className="row">
                        <div className="wl_form-section">
                            <div className="wl_form-title">
                                <h2>Don’t miss out on savings!</h2>
                                <p className="subtitle">
                                    The best Rx savings delivered to your inbox!
                                </p>
                            </div>
                            <div className="wl_form-wrap">
                                <WlContactForm className="wl_form-form" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WlPromoPage;
