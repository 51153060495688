import client from "./client";

const ENDPOINT = "/drugs";

export const DoGetDrugInfoForCoupon = async (ndc) => {
    try {
        const response = await client.post(ENDPOINT, {
            action: "info/coupon",
            data: {
                ndc,
            },
        });
        return response.data;
    } catch (error) {
        return { error };
    }
};

export const DoCreateSubscriptionRequest = async (email) => {
    try {
        const response = await client.post("mail/subscribe", { email });
        if (response?.data?.success === true) {
            return true;
        } else {
            throw new Error();
        }
    } catch (error) {
        return false;
    }
};

export const DoSendQuestionRequest = async (data) => {
    try {
        const response = await client.post("mail/send", data);
        if (response?.data?.success === true) {
            return true;
        } else {
            throw new Error();
        }
    } catch (error) {
        // console.log(error);
        return false;
    }
};
export const DoSendWlFormRequest = async (data) => {
    try {
        const response = await client.post("mail/send", data);
        if (response?.data?.success === true) {
            return true;
        } else {
            throw new Error();
        }
    } catch (error) {
        // console.log(error);
        return false;
    }
};
